<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker @change="onChange" :value="createdDate" :showTime="{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
              }" format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate">
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <jeepay-text-up :placeholder="'退款订单号'" :msg="searchData.refundOrderId" v-model="searchData.refundOrderId" />
            <jeepay-text-up :placeholder="'商户退款单号'" :msg="searchData.mchRefundNo" v-model="searchData.mchRefundNo" />
            <jeepay-text-up :placeholder="'支付订单号'" :msg="searchData.payOrderId" v-model="searchData.payOrderId" />
            <jeepay-text-up :placeholder="'渠道订单号'" :msg="searchData.channelPayOrderNo"
              v-model="searchData.channelPayOrderNo" />
            <a-form-item label="" class="table-head-layout" :wrapper-col="{ span: 16 }">
              <a-select v-model="searchData.appId" placeholder="选择应用" show-search optionFilterProp="label"
                :allowClear="true">
                <!-- <a-select-option key="" >全部应用</a-select-option> -->
                <a-select-option v-for="(item) in mchAppList" :key="item.appId" :value="item.appId"
                  :label="item.appName">{{ item.appName }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.state" placeholder="退款状态" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">订单生成</a-select-option>
                <a-select-option value="1">退款中</a-select-option>
                <a-select-option value="2">退款成功</a-select-option>
                <a-select-option value="3">退款失败</a-select-option>
              </a-select>
            </a-form-item>

            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryFunc" :loading="btnLoading">搜索</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
              <a-button type="primary" icon="search" @click="exportfunc">导出</a-button>
            </span>
          </div>
        </a-form>
        <!--统计信息-->
        <div style="margin-bottom:20px">
          <a-button type="primary" @click="showStatisticsClick">
            <a-icon type="bar-chart" /> {{ showStatistics ? '关闭统计' : '显示统计' }}
          </a-button>
          <div class="statistics-view" v-if="showStatistics" :searchData="searchData">
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>实际退款金额</span>
                <span class="amount-view">{{ refundOrderInfo.refundAmount | curreny}}<span class="text-view">元</span></span>
              </div>
            </div>
            <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>退款订单数</span>
                <span class="amount-black-view">{{ refundOrderInfo.refundCount }}<span class="text-view">笔</span></span>
                <!-- <span style="margin-top: 10px;">0笔<a-button type="link" style="padding:5px"
                    @click="detail">明细</a-button></span> -->
              </div>
            </div>
            <!--<a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>手续费金额</span>
                <span class="amount-black-view">{{payOrderInfo.mchFeeAmount}}<span class="text-view">元</span></span>
              </div>
            </div>-->
            <!-- <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>退款订单</span>
                <span class="amount-h-view">0.00<span class="text-view">元</span></span>
                <span style="margin-top: 10px;">0笔</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- 列表渲染 -->
      <JeepayTable @btnLoadClose="btnLoading = false" ref="infoTable" :initData="true" :closable="true"
        :searchData="searchData" :reqTableDataFunc="reqTableDataFunc" :tableColumns="tableColumns"
        rowKey="refundOrderId" :scrollX="1300">
        <template slot="payAmountSlot" slot-scope="{record}"><b>￥{{ record.payAmount / 100 }}</b></template>
        <!-- 自定义插槽 -->
        <template slot="refundAmountSlot" slot-scope="{record}"><b>￥{{ record.refundAmount / 100 }}</b></template>
        <!-- 自定义插槽 -->
        <template slot="stateSlot" slot-scope="{record}">
          <div><a-tag :key="record.state"
              :color="record.state === 0 ? 'blue' : record.state === 1 ? 'orange' : record.state === 2 ? 'green' : 'volcano'">
              {{ record.state === 0 ? '订单生成' : record.state === 1 ? '退款中' : record.state === 2 ? '退款成功' : record.state ===
                3 ? '退款失败' : record.state === 4 ?'任务关闭':'未知' }}
            </a-tag>
          </div>
        </template>
        <template slot="appName" slot-scope="{record}">{{ getAppName(record.appId) }}</template>
        <template slot="opSlot" slot-scope="{record}"> <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button type="link" v-if="$access('ENT_REFUND_ORDER_VIEW')"
              @click="detailFunc(record.refundOrderId)">详情</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>
    <!-- 日志详情抽屉 -->
    <template>
      <a-drawer width="50%" placement="right" :closable="true" :visible="visible" :title="visible === true ? '退款订单详情' : ''"
        @close="onClose">
        <a-row justify="space-between" type="flex">
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="所属系统">
                {{ detailData.mchType === 1 ? '普通商户' : detailData.mchType === 2 ? '特约商户' : '未知' }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="服务商号">
                {{ detailData.isvNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="退款订单号">
                <a-tag color="purple">
                  {{ detailData.refundOrderId }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户号">
                {{ detailData.mchNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付订单号">
                {{ detailData.payOrderId }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户退款单号">
                {{ detailData.mchRefundNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="渠道支付订单号">
                {{ detailData.channelPayOrderNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="应用APPID">
                {{ detailData.appId }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付金额">
                <a-tag color="green">
                  {{ detailData.payAmount / 100 }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="退款金额">
                <a-tag color="green">
                  {{ detailData.refundAmount / 100 }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="订单状态">
                <a-tag
                  :color="detailData.state === 0 ? 'blue' : detailData.state === 1 ? 'orange' : detailData.state === 2 ? 'green' : 'volcano'">
                  {{ detailData.state === 0 ? '订单生成' : detailData.state === 1 ? '退款中' : detailData.state ===
                    2 ? '退款成功' : detailData.state === 3 ? '退款失败' : detailData.state === 4?'任务关闭':'未知' }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="24">
            <a-descriptions>
              <a-descriptions-item label="退款成功时间">
                {{ detailData.successTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="创建时间">
                {{ detailData.createdAt }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="更新时间">
                {{ detailData.updatedAt }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-divider />
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="接口代码">
                {{ detailData.ifCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="货币代码">
                {{ detailData.currency }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="方式代码">
                {{ detailData.wayCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="客户端IP">
                {{ detailData.clientIp }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="24">
            <a-descriptions>
              <a-descriptions-item label="异步通知地址">
                {{ detailData.notifyUrl }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
        <a-divider />
        <a-col :sm="12">
          <a-descriptions>
            <a-descriptions-item label="渠道订单号">
              {{ detailData.channelOrderNo }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :sm="12">
          <a-descriptions>
            <a-descriptions-item label="渠道错误码">
              {{ detailData.errCode }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :sm="12">
          <a-descriptions>
            <a-descriptions-item label="渠道错误描述">
              {{ detailData.errMsg }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :sm="24">
          <a-form-model-item label="渠道额外参数">
            <a-input type="textarea" disabled="disabled" style="height: 100px;color: black"
              v-model="detailData.channelExtra" />
          </a-form-model-item>
        </a-col>
        <a-divider />
        <a-col :sm="24">
          <a-form-model-item label="扩展参数">
            <a-input type="textarea" disabled="disabled" style="height: 100px;color: black"
              v-model="detailData.extParam" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24">
          <a-form-model-item label="备注">
            <a-input type="textarea" disabled="disabled" style="height: 100px;color: black"
              v-model="detailData.remark" />
          </a-form-model-item>
        </a-col>
      </a-drawer>
    </template>
  </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import request from '@/http/request'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { API_URL_REFUND_ORDER_LIST, API_URL_MCH_APP, API_URL_REFUND_ORDER_COUNT, req } from '@/api/manage'
import moment from 'moment'
import appId from '@/views/Utils/appId'
import { getNowStartDate, getNowEndDate } from '@/utils/util'
import { get } from 'store'
// eslint-disable-next-line no-unused-vars
const tableColumns = [
  { title: '应用名称', scopedSlots: { customRender: 'appName' }, width: 100 },
  { key: 'payAmount', title: '支付金额', scopedSlots: { customRender: 'payAmountSlot' } },
  { key: 'refundAmount', title: '退款金额', scopedSlots: { customRender: 'refundAmountSlot' } },
  { key: 'refundOrderId', title: '退款订单号', dataIndex: 'refundOrderId' },
  { key: 'mchRefundNo', title: '商户退款单号', dataIndex: 'mchRefundNo' },
  { key: 'payOrderId', title: '支付订单号', dataIndex: 'payOrderId' },
  { key: 'channelPayOrderNo', title: '渠道订单号', dataIndex: 'channelPayOrderNo' },
  { key: 'state', title: '支付状态', scopedSlots: { customRender: 'stateSlot' } },
  { key: 'createdAt', dataIndex: 'createdAt', title: '创建日期' },
  { key: 'op', title: '操作', width: '100px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]

export default {
  name: 'IsvListPage',
  mixins: [appId],
  components: { JeepayTable, JeepayTableColumns, JeepayTextUp },
  data() {
    return {
      showStatistics: false,
      btnLoading: false,
      tableColumns: tableColumns,
      searchData: {
        createdStart:getNowStartDate(), // 选择开始时间
        createdEnd: getNowEndDate(), // 选择结束时间
      },
      createdStart: '', // 选择开始时间
      createdEnd: '', // 选择结束时间
      visible: false,
      detailData: {},
      mchAppList: [], // 商户app列表
      refundOrderInfo: {},
      createdDate:[getNowStartDate(),getNowEndDate()]
    }
  },
  computed: {
  },
  mounted() {
    req.list(API_URL_MCH_APP, { pageSize: -1 }).then(res => {
      this.mchAppList = res.records
    })
    this.reqRefundOrderCount()
  },
  methods: {
    showStatisticsClick() {
      this.showStatistics = !this.showStatistics
    },
    queryFunc() {
      this.btnLoading = true
      this.$refs.infoTable.refTable(true)
      this.reqRefundOrderCount()
    },
    // 请求统计接口数据
    reqRefundOrderCount() {
      var params = {
        createdStart: this.searchData.createdStart,
        createdEnd: this.searchData.createdEnd,
        mchName: this.searchData.mchName,
        appId: this.searchData.appId,
        state: this.searchData.state
      }
      req.list(API_URL_REFUND_ORDER_COUNT, params).then(res => {
        this.refundOrderInfo = res
      })
    },
    // 请求table接口数据
    reqTableDataFunc: (params) => {
      return req.list(API_URL_REFUND_ORDER_LIST, params)
    },
    searchFunc: function () { // 点击【查询】按钮点击事件
      this.$refs.infoTable.refTable(true)
    },
    detailFunc: function (recordId) {
      const that = this
      req.getById(API_URL_REFUND_ORDER_LIST, recordId).then(res => {
        that.detailData = res
      })
      this.visible = true
    },
    moment,
    onChange(date, dateString) {
      this.createdDate = dateString
      this.searchData.createdStart = dateString[0] // 开始时间
      this.searchData.createdEnd = dateString[1] // 结束时间
    },
    disabledDate(current) { // 今日之后日期不可选
      return current && current > moment().endOf('day')
    },
    exportfunc() {
      request.request({
        url: '/mch/refundOrder/exportExcel',
        method: 'post',
        data: this.searchData,
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        const blob = new Blob([res])
        link.href = URL.createObjectURL(blob)
        link.download = '支付订单文件下载.xlsx' // 下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((err) => {
        const link = document.createElement('a')
        const blob = new Blob([err], { type: 'application/vnd.ms-excel' })
        link.href = URL.createObjectURL(blob)
        link.download = '支付订单文件下载.xlsx' // 下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    onClose() {
      this.visible = false
    },
    reset() {
      this.searchData = {
        createdStart: getNowStartDate(),
        createdEnd: getNowEndDate()
      }
      this.createdDate = [getNowStartDate(), getNowEndDate()]
      this.$nextTick(() => { this.$refs.infoTable.refTable(true) })
    }
  }
}
</script>
<style scoped lang="less">
.statistics-view {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  margin-top: 20px;
  padding: 30px 0px 30px 0px;

}

.statistics-item-view {
  flex-direction: column;
  display: flex;
  text-align: start;
  align-items: center;
}

.amount-view {
  font-size: 20px;
  color: #5485f8;
  font-weight: bold;
  margin-top: 10px;
}

.amount-black-view {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-top: 10px;
}

.amount-h-view {
  font-size: 20px;
  color: #dbaf0e;
  font-weight: bold;
  margin-top: 10px;
}

.text-view {
  font-size: 14px;
  font-weight: normal;
}

.mx-text {
  font-size: 14px;
  font-weight: normal;
  color: #55a2ff;
  padding-left: 10px;
}
</style>
