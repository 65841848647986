<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker
                :value="createDate"
                @change="onChange"
                       :showTime="{
  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
              >
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <jeepay-text-up placeholder="分账接受者ID" :msg="searchData.receiverId" v-model="searchData.receiverId" />
            <jeepay-text-up placeholder="分账账号组ID" :msg="searchData.receiverGroupId" v-model="searchData.receiverGroupId" />
            <a-form-item label="" class="table-head-layout" :wrapper-col="{span: 16}">
             <a-select v-model="searchData.appId" placeholder="选择应用"  show-search  optionFilterProp="label" :allowClear="true">
                <!-- <a-select-option key="" >全部应用</a-select-option> -->
                <a-select-option v-for="(item) in mchAppList" :key="item.appId" :value="item.appId"  :label="item.appName">{{ item.appName }}</a-select-option>
              </a-select>
            </a-form-item>
            <jeepay-text-up placeholder="支付订单号" :msg="searchData.payOrderId" v-model="searchData.payOrderId"/>
            <jeepay-text-up placeholder="分账接收账号" :msg="searchData.accNo" v-model="searchData.accNo"/>

            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.state" placeholder="分账状态" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">待分账</a-select-option>
                <a-select-option value="1">分账成功</a-select-option>
                <a-select-option value="2">分账失败</a-select-option>
                <a-select-option value="4">分账撤销中</a-select-option>
                <a-select-option value="5">分账撤销成功</a-select-option>
                <a-select-option value="6">分账撤销失败</a-select-option>
              </a-select>
            </a-form-item>

            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryFunc" :loading="btnLoading">搜索</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
              <a-button style="margin-left: 8px" type="primary" icon="search" @click="asynExportfunc">导出</a-button>
            </span>
          </div>
        </a-form>
      </div>

      <!-- 列表渲染 -->
      <JeepayTable
        @btnLoadClose="btnLoading=false"
        ref="infoTable"
        :initData="true"
        :reqTableDataFunc="reqTableDataFunc"
        :tableColumns="tableColumns"
        :searchData="searchData"
        rowKey="recordId"
      >
        <template slot="amountSlot" slot-scope="{record}"><b>￥{{ record.calDivisionAmount/100 }}</b></template> <!-- 自定义插槽 -->
        <template slot="stateSlot" slot-scope="{record}">
          <a-tag :key="record.state" :color="record.state === 1||record.state === 5?'green':record.state === 0||record.state === 4?'orange':'volcano'">
            <span v-if="record.state==0" >待分账</span>
            <span v-else-if="record.state==1" >分账成功</span>
            <span v-else-if="record.state==2" >分账失败</span>
            <span v-else-if="record.state==4" >分账撤销中</span>
            <span v-else-if="record.state==5" >分账撤销成功</span>
            <span v-else-if="record.state==6" >分账撤销失败</span>
            <span v-else>未知</span>
          </a-tag>
        </template>
        <template slot="opSlot" slot-scope="{record}">  <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button type="link" v-if="$access('ENT_DIVISION_RECORD_VIEW')" @click="detailFunc(record.recordId)">详情</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>

    <Detail ref="recordDetail" />

  </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { API_URL_PAY_ORDER_DIVISION_RECORD_LIST, API_URL_MCH_APP, req } from '@/api/manage'
import moment from 'moment'
import Detail from './Detail'
import request from '@/http/request'
import { getNowStartDate, getNowEndDate } from '@/utils/util'
// eslint-disable-next-line no-unused-vars
const tableColumns = [
  { key: 'calDivisionAmount', title: '分账金额', scopedSlots: { customRender: 'amountSlot' }, width: '120px' },
  { key: 'batchOrderId', title: '分账批次号', dataIndex: 'batchOrderId', width: '120px' },
  { key: 'payOrderId', title: '支付订单号', dataIndex: 'payOrderId', width: '120px' },
  { key: 'paymentConfirmId', title: '支付确认对象id', dataIndex: 'paymentConfirmId', width: '150px' },
  { key: 'paymentConfirmOrderNo', title: '订单号', dataIndex: 'paymentConfirmOrderNo', width: '140px'},
  { key: 'ifCode', title: '接口代码', dataIndex: 'ifCode', width: '120px' },
  { key: 'payOrderAmount', dataIndex: 'payOrderAmount', title: '订单金额', customRender: (text) => (text / 100).toFixed(2), width: '120px' },
  { key: 'payOrderDivisionAmount', dataIndex: 'payOrderDivisionAmount', title: '分账基数', customRender: (text) => (text / 100).toFixed(2), width: '120px' },
  { key: 'receiverAlias', title: '账号别名', dataIndex: 'receiverAlias', width: '120px' },
  { key: 'accNo', title: '接收账号', dataIndex: 'accNo', width: '120px' },
  { key: 'accName', title: '账号姓名', dataIndex: 'accName', width: '120px' },
  { key: 'relationTypeName', title: '分账关系类型', dataIndex: 'relationTypeName', width: '120px' },
  { key: 'divisionProfit', dataIndex: 'divisionProfit', title: '分账比例', customRender: (text, record, index) => (text * 100).toFixed(2) + '%', width: '120px' },
  { key: 'divisionFeeAmount', dataIndex: 'divisionFeeAmount', title: '分账手续费', customRender: (text) => '￥' + (text / 100).toFixed(2), width: '120px' },
  { key: 'state', title: '分账状态', scopedSlots: { customRender: 'stateSlot' }, width: '120px' },
  { key: 'createdAt', dataIndex: 'createdAt', title: '创建日期', width: '120px' },
  { key: 'op', title: '操作', width: '100px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]

export default {
  components: { JeepayTable, JeepayTableColumns, JeepayTextUp, Detail },
  data () {
    return {
      btnLoading: false,
      tableColumns: tableColumns,
      searchData: {
        createdStart: getNowStartDate(), // 选择开始时间
        createdEnd: getNowEndDate(), // 选择结束时间
      },
      createdStart: '', // 选择开始时间
      createdEnd: '', // 选择结束时间
      mchAppList: [], // 商户app列表
      createDate:[getNowStartDate(),getNowEndDate()]
    }
  },
  computed: {
  },
  mounted () {
      req.list(API_URL_MCH_APP, { pageSize: -1 }).then(res => {
      this.mchAppList = res.records
      })
  },
  methods: {
    queryFunc () {
      this.btnLoading = true
      this.$refs.infoTable.refTable(true)
    },
    // 请求table接口数据
    reqTableDataFunc: (params) => {
      return req.list(API_URL_PAY_ORDER_DIVISION_RECORD_LIST, params)
    },
    searchFunc: function () { // 点击【查询】按钮点击事件
      this.$refs.infoTable.refTable(true)
    },
    detailFunc: function (recordId) {
      this.$refs.recordDetail.show(recordId)
    },
    moment,
    onChange(date, dateString) {
      this.createDate = dateString
      this.searchData.createdStart = dateString[0] // 开始时间
      this.searchData.createdEnd = dateString[1] // 结束时间
    },
    disabledDate (current) { // 今日之后日期不可选
      return current && current > moment().endOf('day')
    },
    onClose () {
      this.visible = false
    },
    exportfunc() {
      request.request({
        url: '/mch/division/records/export',
        method: 'post',
        data: this.searchData,
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        const blob = new Blob([res])
        link.href = URL.createObjectURL(blob)
        link.download = '分账订单文件下载.xlsx' // 下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((err) => {
        const link = document.createElement('a')
        const blob = new Blob([err], { type: 'application/vnd.ms-excel' })
        link.href = URL.createObjectURL(blob)
        link.download = '分账订单文件下载.xlsx' // 下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    asynExportfunc () {
      request.request({
        url: '/mch/division/records/asynExport',
        method: 'post',
        data: this.searchData
      }).then(res => {
        this.$message.success(res)
      })
    },
    reset() {
      this.searchData = {
        createdStart: getNowStartDate(),
        createdEnd: getNowEndDate()
      }
      this.createDate = [getNowStartDate(), getNowEndDate()]
      this.$nextTick(() => { this.$refs.infoTable.refTable(true) })
    }
  }
}
</script>
