<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker @change="onChange" v-model="datePickerValue" format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate">
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <jeepay-text-up :placeholder="'支付订单号'" :msg="searchData.payOrderId" v-model="searchData.payOrderId" />
            <jeepay-text-up :placeholder="'商户订单号'" :msg="searchData.mchOrderNo" v-model="searchData.mchOrderNo" />
            <jeepay-text-up :placeholder="'微信/支付宝单号'" :msg="searchData.outTransId" v-model="searchData.outTransId" />
            <a-form-item label="" class="table-head-layout" :wrapper-col="{span: 16}">
             <a-select v-model="searchData.appId" placeholder="选择应用" show-search  optionFilterProp="label" :allowClear="true">
                <!-- <a-select-option key="" >全部应用</a-select-option> -->
                <a-select-option v-for="(item) in mchAppList" :key="item.appId" :value="item.appId"  :label="item.appName">{{ item.appName }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.state" placeholder="支付状态" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">订单生成</a-select-option>
                <a-select-option value="1">支付中</a-select-option>
                <a-select-option value="2">支付成功</a-select-option>
                <a-select-option value="3">支付失败</a-select-option>
                <a-select-option value="4">已撤销</a-select-option>
                <a-select-option value="5">已退款</a-select-option>
                <a-select-option value="6">订单关闭</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.wayCode" placeholder="支付方式" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="item.wayCode" v-for="item in payWayList" :value="item.wayCode">
                  {{ item.wayName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryFunc" :loading="btnLoading">搜索</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
              <a-button type="primary" icon="search" @click="asynExportfunc" >导出</a-button>
            </span>
          </div>
        </a-form>
        <!--统计信息-->
        <div style="margin-bottom:20px">
          <a-button type="primary"   @click="showStatisticsClick">
            <a-icon type="bar-chart" /> {{ showStatistics ? '关闭统计' : '显示统计' }}
            </a-button>
          <div class="statistics-view" v-if="showStatistics" :searchData="searchData">
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>实际收款金额</span>
                <span class="amount-view">{{payOrderInfo.payAmount | curreny}}<span class="text-view">元</span></span>
              </div>
            </div>
            <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>成交订单数</span>
                <span class="amount-black-view">{{payOrderInfo.payCount}}<span class="text-view">笔</span></span>
                <!-- <span style="margin-top: 10px;">0笔<a-button type="link" style="padding:5px"
                    @click="detail">明细</a-button></span> -->
              </div>
            </div>
            <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>手续费金额</span>
                <span class="amount-black-view">{{payOrderInfo.mchFeeAmount | curreny}}<span class="text-view">元</span></span>
              </div>
            </div>
            <!-- <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>退款订单</span>
                <span class="amount-h-view">0.00<span class="text-view">元</span></span>
                <span style="margin-top: 10px;">0笔</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- 列表渲染 -->
      <JeepayTable
        @btnLoadClose="btnLoading=false"
        ref="infoTable"
        :initData="true"
        :reqTableDataFunc="reqTableDataFunc"
        :tableColumns="tableColumns"
        :searchData="searchData"
        rowKey="payOrderId"
        :scrollX="1000"
      >
        <template slot="payOrderIdSlot" slot-scope="{record}">{{ record.payOrderId }}</template>
        <template slot="amountSlot" slot-scope="{record}"><b>￥{{ record.amount/100 |  curreny}}</b></template> <!-- 自定义插槽 -->
        <template slot="stateSlot" slot-scope="{record}">
          <a-tag
            :key="record.state"
            :color="record.state === 0?'blue':record.state === 1?'orange':record.state === 2?'green':'volcano'"
          >
            {{ record.state === 0?'订单生成':record.state === 1?'支付中':record.state === 2?'支付成功':record.state === 3?'支付失败':record.state === 4?'已撤销':record.state === 5?'已退款':record.state === 6?'订单关闭':'未知' }}
          </a-tag>
        </template>
            <template slot="refundStateSlot" slot-scope="{record}">
          <a-tag
            :key="record.refundState"
            :color="record.refundState === 0?'blue':record.refundState === 1?'orange':record.refundState === 2?'green':'volcano'"
          >
            {{ record.refundState === 0?'未发起':record.refundState === 1?'部分退款':record.refundState === 2?'全额退款':'未知' }}
          </a-tag>
        </template>
        <template slot="appName" slot-scope="{record}">{{ getAppName(record.appId)}}</template>
        <template slot="opSlot" slot-scope="{record}">  <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button type="link" v-if="$access('ENT_PAY_ORDER_VIEW')" @click="detailFunc(record.payOrderId)">详情</a-button>
            <a-button type="link" v-if="$access('ENT_PAY_ORDER_REFUND')" style="color: red" v-show="(record.state === 2 && record.refundState !== 2)" @click="openFunc(record, record.payOrderId)">退款</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>
  <!-- 退款弹出框 -->
    <refund-modal ref="refundModalInfo" :callbackFunc="searchFunc"></refund-modal>
    <!-- 日志详情抽屉 -->
    <template>
      <a-drawer
        width="50%"
        placement="right"
        :closable="true"
        :visible="visible"
        :title="visible === true? '订单详情':''"
        @close="onClose"
      >
        <a-row justify="space-between" type="flex">
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="服务商号">
                {{ detailData.isvNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付订单号">
                <a-tag color="purple">
                  {{ detailData.payOrderId }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户号">
                {{ detailData.mchNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户订单号">
                {{ detailData.mchOrderNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户名称">
                {{ detailData.mchName }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="应用APPID">
                {{ detailData.appId }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="应用名称">
                {{ getAppName(detailData.appId) }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="订单状态">
                <a-tag :color="detailData.state === 0?'blue':detailData.state === 1?'orange':detailData.state === 2?'green':'volcano'">
                  {{ detailData.state === 0?'订单生成':detailData.state === 1?'支付中':detailData.state === 2?'支付成功':detailData.state === 3?'支付失败':detailData.state === 4?'已撤销':detailData.state === 5?'已退款':detailData.state === 6?'订单关闭':'未知' }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付金额">
                <a-tag color="green">
                  {{ detailData.amount/100 }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付错误码">
                {{ detailData.errCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付错误描述">
                {{ detailData.errMsg }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="订单失效时间">
                {{ detailData.expiredTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付成功时间">
                {{ detailData.successTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="创建时间">
                {{ detailData.createdAt }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="更新时间">
                {{ detailData.updatedAt }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-divider />
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商品标题">
                {{ detailData.subject }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商品描述">
                {{ detailData.body }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="接口代码">
                {{ detailData.ifCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="货币代码">
                {{ detailData.currency }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付方式">
                {{ detailData.wayCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="客户端IP">
                {{ detailData.clientIp }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="用户标识">
                {{ detailData.channelUser }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="渠道订单号">
                {{ detailData.channelOrderNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="异步通知地址">
                {{ detailData.notifyUrl }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="页面跳转地址">
                {{ detailData.returnUrl }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="退款次数">
                {{ detailData.refundTimes }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="退款总额">
                <a-tag color="cyan" v-if="detailData.refundAmount">
                  {{ detailData.refundAmount/100 }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
        <a-divider />
        <a-row justify="start" type="flex">
          <a-col :sm="24">
            <a-form-model-item label="扩展参数">
              <a-input
                type="textarea"
                disabled="disabled"
                style="height: 100px;color: black"
                v-model="detailData.extParam"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-drawer>
    </template>
  </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import request from '@/http/request'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { API_URL_PAY_ORDER_LIST, API_URL_PAYWAYS_LIST, API_URL_MCH_APP, API_URL_PAY_ORDER_COUNT, req } from '@/api/manage'
import moment from 'moment'
import RefundModal from './RefundModal' // 退款弹出框
import appId from '@/views/Utils/appId'
import { getStartDate, getEndDate } from '@/utils/util'
// eslint-disable-next-line no-unused-vars
const tableColumns = [
  { key: 'payOrderId', title: '支付订单号', width: '160px', fixed: 'left', scopedSlots: { customRender: 'payOrderIdSlot' } },
  { key: 'mchOrderNo', title: '商户订单号', dataIndex: 'mchOrderNo', width: '160px' },
  { key: 'outTransId', title: '微信/支付宝单号', dataIndex: 'outTransId', width: '160px' },
  { key: 'amount', title: '支付金额', ellipsis: true, width: '120px', scopedSlots: { customRender: 'amountSlot' } },
  { key: 'mchFeeAmount', dataIndex: 'mchFeeAmount', title: '手续费', width: '90px', customRender: (text) => '￥' + (text / 100).toFixed(2) },
  { key: 'wayName', title: '支付方式', dataIndex: 'wayName', width: '165px' },
  { title: '应用名称',  scopedSlots: { customRender: 'appName' }, width: 100},
  { key: 'state', title: '支付状态', scopedSlots: { customRender: 'stateSlot' }, width: '110px' },
  { key: 'successTime', dataIndex: 'successTime', title: '支付时间', width: '170px' },
  { key: 'createdAt', dataIndex: 'createdAt', title: '创建日期', width: '170px' },
  { key: 'refundState', title: '退款状态', scopedSlots: { customRender: 'refundStateSlot' }, width: '115px' },
  { key: 'subject', title: '商品标题', dataIndex: 'subject', width: '150px' },
  { key: 'op', title: '操作', width: '150px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]

export default {
  name: 'IsvListPage',
  mixins:[appId],
  components: { JeepayTable, JeepayTableColumns, JeepayTextUp, RefundModal },
  data () {
    return {
      showStatistics: false,
      btnLoading: false,
      tableColumns: tableColumns,
      searchData: {
        createdStart: getStartDate(), // 选择开始时间
        createdEnd: getEndDate() // 选择结束时间
      },
      visible: false,
      detailData: {},
      payWayList: [],
      payOrderInfo: {},
      mchAppList: [], // 商户app列表
      datePickerValue: []
    }
  },
  computed: {
  },
  mounted () {
    this.initDate()
    req.list(API_URL_MCH_APP, { pageSize: -1 }).then(res => {
    this.mchAppList = res.records
    })
    this.initPayWay()
    this.reqPayOrderCount()
  },
  methods: {
      showStatisticsClick () {
        this.showStatistics = !this.showStatistics
      },
      reset () {
        this.searchData = {}
        this.initDate()
        // req.list(API_URL_MCH_APP, { pageSize: -1 }).then(res => {
        // this.mchAppList = res.records
        // })
        // this.initPayWay()
        // this.reqPayOrderCount()
      },
    queryFunc () {
      this.btnLoading = true
      this.$refs.infoTable.refTable(true)
      this.reqPayOrderCount()
    },
    exportfunc () {
        request.request({
          url: '/mch/payOrder/exportExcel',
          method: 'post',
          data: this.searchData,
          responseType: 'blob'
        }).then(res => {
              const link = document.createElement('a')
              const blob = new Blob([res])
              link.href = URL.createObjectURL(blob)
              link.download = '支付订单文件下载.xlsx' // 下载的文件名
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
        }).catch((err) => {
                 const link = document.createElement('a')
              const blob = new Blob([err], { type: 'application/vnd.ms-excel' })
              link.href = URL.createObjectURL(blob)
              link.download = '支付订单文件下载.xlsx' // 下载的文件名
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
      })
    },
    asynExportfunc () {
      request.request({
        url: '/mch/payOrder/asynExport',
        method: 'post',
        data: this.searchData
      }).then(res => {
        this.$message.success(res)
      })
    },
    reqTableDataFunc: (params) => {
      return req.list(API_URL_PAY_ORDER_LIST, params)
    },
    searchFunc: function () { // 点击【查询】按钮点击事件
      this.$refs.infoTable.refTable(true)
    },
      // 请求统计接口数据
    reqPayOrderCount () {
      var params = {
        createdStart: this.searchData.createdStart,
        createdEnd: this.searchData.createdEnd,
        payOrderId: this.searchData.payOrderId,
        mchOrderNo: this.searchData.mchOrderNo,
        outTransId: this.searchData.outTransId,
        mchName: this.searchData.mchName,
        appId: this.searchData.appId,
        state: this.searchData.state
      }
      req.list(API_URL_PAY_ORDER_COUNT, params).then(res => {
        this.payOrderInfo = res
      })
    },
       // 打开退款弹出框
    openFunc (record, recordId) {
      if (record.refundState === 2) {
        return this.$infoBox.modalError('订单无可退款金额', '')
      }
      this.$refs.refundModalInfo.show(recordId)
    },
    detailFunc: function (recordId) {
      const that = this
      req.getById(API_URL_PAY_ORDER_LIST, recordId).then(res => {
        that.detailData = res
      })
      this.visible = true
    },
    moment,
    onChange (date, dateString) {
      this.searchData.createdStart = dateString[0] // 开始时间
      this.searchData.createdEnd = dateString[1] // 结束时间
    },
    disabledDate (current) { // 今日之后日期不可选
      return current && current > moment().endOf('day')
    },
    onClose () {
      this.visible = false
    },
    initPayWay: function () {
      const that = this
      req.list(API_URL_PAYWAYS_LIST, { 'pageSize': -1 }).then(res => { // 支付方式下拉列表
        that.payWayList = res.records
      })
    },
    initDate () {
      // 页面展示用
      this.datePickerValue = []
      this.datePickerValue.push(getStartDate())
      this.datePickerValue.push(getEndDate())
      // 后端接口传参用
      this.searchData.createdStart = this.datePickerValue[0] // 开始时间
      this.searchData.createdEnd = this.datePickerValue[1] // 结束时间
    }
  }
}
</script>
<style scoped lang="less">
.statistics-view {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  margin-top: 20px;
  padding: 30px 0px 30px 0px;

}

.statistics-item-view {
  flex-direction: column;
  display: flex;
  text-align: start;
  align-items: center;
}

.amount-view {
  font-size: 20px;
  color: #5485f8;
  font-weight: bold;
  margin-top: 10px;
}

.amount-black-view {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-top: 10px;
}

.amount-h-view {
  font-size: 20px;
  color: #dbaf0e;
  font-weight: bold;
  margin-top: 10px;
}

.text-view {
  font-size: 14px;
  font-weight: normal;
}

.mx-text {
  font-size: 14px;
  font-weight: normal;
  color: #55a2ff;
  padding-left: 10px;
}
</style>
