
// 定义全局自增ID
var atomicLong = 1

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/** 生成自增序列号（不重复） **/
export function genRowKey () {
  return new Date().getTime() + '_' + (atomicLong++)
}

export function getStartDate () {
  const now = new Date()
  now.setDate(now.getDate() - 6)
  return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' 00:00:00'
}
export function getEndDate () {
  var date = new Date()
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 23:59:59'
}

// 设置当天的时间
export function getNowStartDate() {
  const now = new Date()
  return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' 00:00:00'
}
export function getNowEndDate() {
  const now = new Date()
  return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' 23:59:59'
}
