export default {
    methods: {
        getAppName(appId) {
            var appName = ''
            if (this.mchAppList) {
                this.mchAppList.map(item => {
                    if (item.appId === appId) {
                        appName = item.appName;
                    }
                })
            }
            return appName ? appName : ""
        }
    }
}